import React, { useState } from 'react';
import { useAuth } from '../../AuthProvider';
import DeleteAccount from '../../DeleteAccount';

const Login = () => {
  const { loginWithGoogle, loginWithFacebook, loginWithEmail, registerWithEmail } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      await loginWithEmail(email, password);
    } catch (error) {
      console.error('Error logging in with email: ', error);
    }
  };

  const handleEmailRegister = async (e) => {
    e.preventDefault();
    try {
      await registerWithEmail(email, password);
    } catch (error) {
      console.error('Error registering with email: ', error);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <button onClick={loginWithGoogle}>Login with Google</button>
      {/* <button onClick={loginWithFacebook}>Login with Facebook</button> */}
      <form onSubmit={handleEmailLogin}>
        <input 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <button type="submit">Login with Email</button>
      </form>
      <h2>Register</h2>
      <form onSubmit={handleEmailRegister}>
        <input 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <button type="submit">Register with Email</button>
      </form>
      <DeleteAccount/>
    </div>
  );
};

export default Login;
