import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useAuth } from '../../AuthProvider';
import huLocale from '@fullcalendar/core/locales/hu';

const LiveEventsCalendar = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          `https://us-central1-balaton-autosisklola-live.cloudfunctions.net/getLiveEvents`
        );
        const data = await response.json();
        const formattedEvents = data.map(event => ({
          title: event.title,
          start: event.startTime,
          end: event.endTime,
          allDay: event.allDay || false,
        }));
        setEvents(formattedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div className="live-events-calendar">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin]}
        initialView="timeGridWeek"
        timeZone="Europe/Budapest"
        locale={huLocale}
        slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            hour12: false,
        }}
        eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            hour12: false,
        }}
        events={events}
        height="auto"
      />
    </div>
  );
};

export default LiveEventsCalendar;
