import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthProvider';
import CheckoutSimple from '../CheckoutSimple';
import Login from '../Components/Login/Login';
import LiveEventsCalendar from '../Components/Calendar/LiveEventsCalendar';

const VimeoLiveEmbed = () => {
  const [videoId, setVideoId] = useState('');
  const [error, setError] = useState('');
  const { user, hasAccess, loading } = useAuth();

  const { logout } = useAuth();

  const fetchLiveVideoId = async () => {
    try {
      const response = await fetch(
        `https://us-central1-balaton-autosisklola-live.cloudfunctions.net/getVimeoLiveVideo`
      );
      const data = await response.json();
      console.log("Vimeo API Response:", data);

      if (data.videoId) {
        setVideoId(data.videoId);
      } else {
        setError('No live video found.');
      }
    } catch (error) {
      setError(error.message);
      console.error('Error fetching live video:', error);
    }
  };

  useEffect(() => {
    console.log("User:", user);
    console.log("Has Access:", hasAccess);

    if (user && hasAccess) {
      fetchLiveVideoId();
    }
  }, [user, hasAccess]);

  const embedUrl = `https://player.vimeo.com/video/${videoId}?autoplay=1`;
  console.log("Embed URL:", embedUrl);

  return (
    <div className="vimeo-live-embed">
      {loading && <p>Loading...</p>}

      {!loading && !user && <Login />} 

      {!loading && user && !hasAccess && (
        <p>You do not have access to this live video. Please <CheckoutSimple />.</p>
      )}

      {!loading && user && hasAccess && videoId && (
        <iframe
          src={embedUrl}
          width="560"
          height="315"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Vimeo Live Video"
        ></iframe>
      )}

      {!loading && user && hasAccess && !videoId && (
        <p>{error || 'No live stream available.'}</p>
      )}
      
      {user && <button onClick={() => logout()}>Log Out</button>}
      <LiveEventsCalendar />
    </div>
  );
};

export default VimeoLiveEmbed;
