import React, { useEffect } from 'react';
import { useAuth } from './AuthProvider';
import { db } from './Firebase';
import { doc, setDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';

const Success = () => {
    const { user } = useAuth();
    const location = useLocation();
  
    useEffect(() => {
      const confirmSubscription = async () => {
        const queryParams = new URLSearchParams(location.search);
        const uid = queryParams.get('uid');
        const sessionId = queryParams.get('session_id');
  
        if (uid && sessionId) {
          try {
            await setDoc(doc(db, 'subscriptions', uid), { isSubscribed: true }, { merge: true });
            console.log("Subscription confirmed for UID:", uid);
          } catch (error) {
            console.error('Error confirming subscription:', error);
          }
        }
      };
  
      confirmSubscription();
    }, [location]);
  
    return (
      <div>
        <h1>Subscription Successful</h1>
        <p>Thank you for subscribing!</p>
      </div>
    );
  };
  
  export default Success;