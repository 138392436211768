import './App.css';
import {
BrowserRouter as Router,
Routes,
Route
} from 'react-router-dom';
import Home from './Pages/Home';
import Teachers from './Pages/Teachers';
import Prices from './Pages/Prices';
import DataPolicy from './Pages/DataPolicy';
import Courses from './Pages/Courses';
import Contact from './Pages/Contact';
import AkoVsm from './Pages/AkoVsm';
import Footer2 from './Components/Footer/Footer2';
import Navbar from './Components/Home/navbar';
import AllamiTamogatas from './Pages/AllamiTamogatas';
import Aszf from './Pages/Aszf';
import BCategory from './Pages/BCategory';
import ElmeletiKepzes from './Pages/ElmeletiKepzes';
import LoadingSpinner from './Components/Animations/LoadingSpinner';
import { useState, useEffect } from 'react';
import ACategory from './Pages/ACategories';
import { AuthProvider } from './AuthProvider';
import VimeoLiveVideo from './Pages/VimeoLiveVideo';
import Success from './Success';

function App() {

  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000);
  // }, []);

  // if (isLoading) {
  //   return <LoadingSpinner />;
  // }

  return (
    <AuthProvider>
      <div className="App">
        <Navbar />
        <div className='main'>
          <Router>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/oktatok' element={<Teachers />} />
              <Route path='/kurzusok' element={<Courses />} />
              <Route path='/arak' element={<Prices />} />
              <Route path='/kapcsolat' element={<Contact />} />
              <Route path='/adatvedelem' element={<DataPolicy />} />
              <Route path='/akovsm' element={<AkoVsm />} />
              <Route path='/allamitamogatas' element={<AllamiTamogatas />} />
              <Route path='/aszf' element={<Aszf />} />
              <Route path='/bkategoria' element={<BCategory />} />
              <Route path='/elmeletikepzes' element={<ElmeletiKepzes />} />
              <Route path='/akategoriak' element={<ACategory />} />
              <Route path='/live' element={<VimeoLiveVideo />} />
              <Route path="/success" element={<Success />} />
            </Routes>
          </Router>
        </div>
        <Footer2 />
      </div>
      </AuthProvider>
  );
}

export default App;
