import React, { useState, useEffect } from 'react';
import './BlinkingButtonEng.css';

function BlinkingButton() {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setInterval(() => {
            setVisible(prev => !prev);
        }, 500); // blink every 500ms

        return () => clearInterval(timer); // clean up on unmount
    }, []);

    return (
        <>
        <a href="https://etitan.hu/site/WebReg/Registration/hzXvSuWYrUC1_6_zjIBazg" target="_blank" rel="noopener noreferrer">
            <button className={`red-button ${visible ? '' : 'hidden'}`}>
                Click here to apply for english course!
            </button>
        </a>
        </>
    );
}

export default BlinkingButton;