import React from 'react'
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Card from 'react-bootstrap/Card';

//&emsp;- átvett tanuló esetében 10000ft/óra

function Prices() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - Árak'
  }, [])

  const services = [
    { title: 'Elméleti oktatás - B', price: '49.000ft', details: 'e-learning kényelmes otthoni saját tempós oktatásban + elsősegély tananyag (e-learning formában)' },
    { title: 'Elméleti oktatás - AM', price: '45.000ft', details: 'e-learning kényelmes otthoni saját tempós oktatásban' },
    { title: 'Elméleti oktatás - A', price: '49.000ft', details: 'e-learning kényelmes otthoni saját tempós oktatásban' },
    { title: 'Elméleti oktatás - A1', price: '49.000ft', details: 'e-learning kényelmes otthoni saját tempós oktatásban' },
    { title: 'Elméleti oktatás - A2', price: '49.000ft', details: 'e-learning kényelmes otthoni saját tempós oktatásban' },
    { title: 'Gyakorlat - AM, A1, A2, A, B', price: '9500ft/óra', details: 'nálunk végzett elméleti oktatás esetében' },
    { title: 'Gyakorlat - AM, A1, A2, A, B', price: '11000ft/óra', details: 'más képzőszervtől átvett tanuló esetében' },
    { title: 'Angol nyelven való oktatás', price: '14000ft/óra' },
    { title: 'Gyorsított (3 hét alatt 30 óra levezetése)', price: '14000ft/óra' },
    { title: 'Elméleti vizsga díj', price: '4600ft' },
    { title: 'Gyakorlati vizsga díj', price: '11000ft' },
  ];

  return (
    <>
    <div className='all-prices'>
    <div className='title'>
      Díjak
    </div>
    <div className='low-title background-blue'>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 50 }}
      transition={{ duration: 3 }}
      className='row'>
        {services.map((service, i) => (
          <div className="col-12 col-sm-6 col-md-4 mb-4" key={i}>
            <Card style={{ width: '90%', margin: 'auto' }}>
              <Card.Body>
                <Card.Title>{service.title}</Card.Title>
                <Card.Text>
                  {service.details}
                </Card.Text>
                <h3>{service.price}</h3>
              </Card.Body>
            </Card>
          </div>
        ))}
      </motion.div><motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 10 }}
        transition={{ duration: 8 }}
        className='low-title'>
      </motion.div><p className='low-titles'>
        Bankszámlaszámunk:
      </p><p className='low-title-content2'>
        &emsp;BALATON AUTÓSISKOLA KFT.<br />
        &emsp;<b>12023101 01881804 00100009</b><br />
        &emsp;RAIFFEISEN BANK<br />
        &emsp;Közleménybe Név + mit utal (pl.: óra mennyisége)
      </p><p className='nincs-rejtett'>
        Nincs rejtett költség! Nálunk perc alapú vezetés van!
      </p>
      </div>
      </div>
      </>

  )
}
export default Prices;