import React from "react";
import { useEffect, useRef, useState } from 'react';
import MyCalendar from "../Components/Calendar/MyCalendar";
// import BlinkingButton from "../Components/BlinkingButton/BlinkingButton";
// import BlinkingButtonEng from "../Components/BlinkingButton/BlinkingButtonEng";
import { AnimatePresence, motion } from 'framer-motion';
// import Fade from 'react-reveal/Fade';
import { useInView } from 'react-intersection-observer';
import Navbar from "../Components/Home/navbar";
import Carousel from '../Components/Home/carousel'
import Csapat from '../Components/Home/csapat'
import Turelmesek from '../Components/Home/turelmesek'
import Irodakozelites from '../Components/Home/irodakozelites'
import Motto from '../Components/Home/motto'
import Contact1 from '../Components/Home/contact1'

const partnersData = [
    { name: 'Partner 1', logo: './images/Fekete auto webcimmel.png' },
    { name: 'Partner 2', logo: './images/Fekete auto webcimmel.png' },
];


function Home() {
    const videoRef = useRef(null);
    const [playAttemptFailed, setPlayAttemptFailed] = useState(false);

    const toggleVideoPlay = () => {
        const video = videoRef.current;
        if (video.paused) {
            video.play().catch(err => {
                console.error("Error while trying to play video manually:", err);
                setPlayAttemptFailed(true);
            });
        } else {
            video.pause();
        }
    };

    useEffect(() => {
        document.title = 'Balaton Autósiskola'

        const video = videoRef.current;
        if (video) {
            video.muted = false;
            video.play().catch(() => {
                setPlayAttemptFailed(true);
            });
        }
    }, []);

    return (
        <div className="home-container">
            <animate-on-reveal
                animation="bounceIn"
                duration="500ms"
                delay="0s"
                direction="normal"
                easing="ease"
                iteration="1"
            >
                <h1 data-thq-animate-on-reveal="true" className="home-text">
                Üdvözöljük a Balaton Autósiskola honlapján!
                </h1>
            </animate-on-reveal>
            <Carousel rootClassName="carousel-root-class-name"></Carousel>
            <Csapat></Csapat>
            <animate-on-reveal
                animation="bounceIn"
                duration="500ms"
                delay="0s"
                direction="normal"
                easing="ease"
                iteration="1"
            >
                <span data-thq-animate-on-reveal="true" className="home-text01">
                <span>Jelentkezz akár online!</span>
                <br></br>
                </span>
            </animate-on-reveal>
            <div className="home-container1">
                <animate-on-reveal
                animation="pulse"
                duration="500ms"
                iteration="10000000"
                >
                <a href="https://etitan.hu/site/hu/WebReg/IskolaiRegisztracio?azonosito=hzXvSuWYrUC1_6_zjIBazg" target="_blank" rel="noopener noreferrer">
                <button
                    type="button"
                    data-thq-animate-on-reveal="true"
                    className="home-button button"
                >
                    Kattints ide az online jelentkezéshez!
                </button>
                </a>
                </animate-on-reveal>
                <animate-on-reveal
                animation="pulse"
                duration="500ms"
                iteration="10000000"
                >
                <a href="https://etitan.hu/site/WebReg/Registration/hzXvSuWYrUC1_6_zjIBazg" target="_blank" rel="noopener noreferrer">
                <button
                    type="button"
                    data-thq-animate-on-reveal="true"
                    className="home-button1 button"
                >
                    Click here to apply for english course!
                </button>
                </a>
                </animate-on-reveal>
            </div>
            <Turelmesek></Turelmesek>
            <Irodakozelites></Irodakozelites>
            <Motto></Motto>
            <animate-on-reveal
                animation="bounceIn"
                duration="500ms"
                delay="0s"
                direction="normal"
                easing="ease"
                iteration="1"
            >
                <video
                src="./videos/Balaton_autosiskola.mp4"
                poster="./images/Balaton_autosiskola_borito.jpg"
                data-thq-animate-on-reveal="true"
                controls
                playsInline
                className="home-video"
                ref={videoRef}
                onTouchStart={toggleVideoPlay}
                ></video>
            </animate-on-reveal>
            <animate-on-reveal
                animation="bounceIn"
                duration="500ms"
                delay="0s"
                direction="normal"
                easing="ease"
                iteration="1"
            >
                <span data-thq-animate-on-reveal="true" className="home-text04">
                <span>
                    Több éves szakmai tapasztalattal rendelkező oktatóink állnak
                    rendelkezésedre.
                </span>
                <br></br>
                <span>
                    A kresz alapjaitól a magabiztos önálló közlekedésig végigkísérünk!
                </span>
                </span>
            </animate-on-reveal>
            <div className="home-container2">
                <div className="home-container3">
                <animate-on-reveal
                    animation="bounceIn"
                    duration="500ms"
                    delay="0s"
                    direction="normal"
                    easing="ease"
                    iteration="1"
                >
                    <h1 data-thq-animate-on-reveal="true" className="home-text08">
                    Ha bármi kérdésed van írj nekünk!
                    <span
                        dangerouslySetInnerHTML={{
                        __html: ' ',
                        }}
                    />
                    </h1>
                </animate-on-reveal>
                </div>
            </div>
            <animate-on-reveal
                animation="bounceIn"
                duration="500ms"
                delay="0s"
                direction="normal"
                easing="ease"
                iteration="1"
            >
                <a href="/kapcsolat">
                <button
                type="button"
                data-thq-animate-on-reveal="true"
                className="home-button2 button"
                >
                <span className="home-text09">
                    <span>Kapcsolatfelvétel</span>
                    <br></br>
                </span>
                </button>
                </a>
            </animate-on-reveal>
            <Contact1></Contact1>
            <MyCalendar />
            </div>
    );
}

export default Home;