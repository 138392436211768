import React from 'react'
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Fade from 'react-reveal/Fade';

function Aszf() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - ÁSZF'
  }, [])

  /*
        <p className='low-titles-aszf'>
        A tanuló áthelyezése:
      </p>
      <p className='low-title-content2'>
        Amennyiben a tanuló más képzőszervnél kívánja folytatni a tanulmányait, úgy a 
        képzőszerv képzési igazolást állít ki a kéréstől számítva három munkanapon belül. A 
        fel nem használt tandíj visszafizetésre kerül.<br/>
        Más képzőszervtől átvett tanuló esetén a gyakorlati oktatás díja: 10.000 Ft/óra
      </p>
  */


  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="homePage"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className='title'>
          Általános szerződési feltételek
        </div>
        <div className='low-title'>
          <p className='low-titles-aszf'>
            Képzőszerv hivatalos cégadatai:
          </p>
          <p className='low-title-content2'>
            &emsp;-	Balaton Autósiskola Kft<br />
            &emsp;-	e-mail: info@autosiskolabalaton.hu<br />
            &emsp;-	Cégforma: Korlátolt Felelősségű Társaság<br />
            &emsp;- Cégjegyzék száma: 14-09-319907<br />
            &emsp;-	Adószám: 32185089-1-14<br />
            &emsp;-	Felnőttképzési nyilvántartási szám: <br />
            &emsp;-	Raiffeisen Bank: 12023101 01881804 00100009<br />
          </p>
          <Fade bottom>
          <p className='low-titles-aszf'>
            Iskolavezető neve: Horváth Zsolt 06706621010
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-title-content2'>
            Tel.:+3670 6621010 E-mail: info@autosiskolabalaton.hu<br/>
            Ügyfélfogadó címe: 8600 Siófok, Kálmán Imre sétány 8.<br />
            Székhelye: 8600 Siófok, Kristály utca 39.
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles-aszf'>
            Az autósiskola ügyfélfogadási ideje:
          </p>
          <p className='low-title-content2'>
            Páros héten: hétfőtől péntekig --{'>'} 9:00-13:00<br />
            Páratlan héten: hétfőtől péntekig --{'>'} 12:30-16:30<br />
            Minden szombaton --{'>'} 8:00-12:00<br/>
            Tel.: +3620 4038020
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles-aszf'>
            Felügyeleti és engedélyező szerv:
          </p>
          <p className='low-title-content2'>
            Közlekedési Alkalmassági és Vizsgaközpont Nonprofit Korlátolt Felelősségű Társaság
            (Továbbiakban KAV)<br />
            7400 Kaposvár Vásártéri u 2.<br />
            E-mail: somogy@kavk.hu<br />
            <br />
            Építési és Közlekedési Minisztérium<br />
            1054 Budapest, Alkotmány utca 15.<br />
            E-mail: info@ekm.gov.hu<br />
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles'>
            A tanfolyamra való felvétel:
          </p>
          <p className='low-titles-aszf'>
            A képzést előíró jogszabályok:
          </p>
          <p className='low-title-content2'>
            &emsp;-	A közúti közlekedésről szóló 1988. évi I. törvény <br />
            &emsp;-	A közúti járművezetők és a közúti közlekedési szakemberek képzésének
            és vizsgáztatásának általános szabályairól szóló 179/2011. (IX. 2.) Korm. rendelet <br />
            &emsp;-	A közúti járművezetők és a közúti közlekedési szakemberek képzésének
            és vizsgáztatásának részletes szabályairól szóló 24/2005. (IV. 21.) GKM rendelet alapján<br />
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles-aszf'>
            A jelentkezés feltételei:
          </p>
          <p className='low-title-content2'>
            &emsp;-	személyi igazolvány, lakcímkártya, (ha van, vezetői engedély)<br />
            &emsp;-	minimum alapfokú iskolai végzettséget igazoló okmány bemutatása<br />
            &emsp;-	Az előírt egészségi alkalmassági orvosi vélemény (háziorvos)<br />
            &emsp;-	Kiskorú tanulóknál a Képzési Szerződést a szülőnek, vagy a gondviselőnek is alá kell írnia.<br />
            &emsp;-	Esetleg egyéb kategóriás vezetői engedélyről fénymásolat szükséges<br />
            A tanulónak az első elméleti tanfolyam bejelentkezés dátumától számítva 9
            hónapon belül kell az első elméleti vizsgára jelentkeznie és 12 hónapon belül sikeres vizsgát kell tenni.
            A sikeres elméleti vizsga 2 évig érvényes.
            Ezek feltételek nem teljesülése esetén a tanfolyamot meg kell ismételni.
            Elméleti vizsgára csak az bocsátható, aki legfeljebb az előírt életkornál
            3 hónappal fiatalabb. A vizsga feltétele továbbá, még az e-learning rendszer vagy a 
            képzőszerv által kiállított képzési igazolás, illetve aláírt jelentkezési lap, melyet
            személyesen az ügyfélszolgálati irodában tud aláírni.
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles-aszf'>
            Az első elméleti vizsga kiírásának feltételei:
          </p>
          <p className='low-title-content2'>
            &emsp;-	Az aláírt jelentkezési lap, <br />
            &emsp;-	Befizetett vizsgadíj<br />
            &emsp;-	I. fokú orvosi alkalmassági igazolás<br />
            A pótvizsgát a tanuló jogosult személyesen intézni a KAV ügyfélszolgálatán.
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles-aszf'>
            A gyakorlati vizsga:
          </p>
          <p className='low-title-content2'>
            Forgalmi vizsgára csak az bocsátható, aki a kategóriára előírt életkort
            elérte, az elméleti tárgyból sikeresen vizsgázott, valamint a tanfolyamon
            kötelezően előírt gyakorlatot elvégezte, s a kötelezően előírt kilométert
            levezette. Ezen felül az összes levezett óra díját, valamint vizsga díját befizette.
            A tanuló gyakorlati vizsgára bocsátása csak akkor lehetséges, ha minimum 29 órát és 580 kilométert teljesített.<br />
            A pótórák díjai: A kötelező órák, valamint a megtett távolság teljesítése után
            a szakoktató javaslatot tehet a további gyakorlati órák felvételére. A pótórák díja azonos az alapórák díjazásával.
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles'>
            Tan-, valamint vizsgadíj befizetése:
          </p>
          <p className='low-titles-aszf'>
            Fizetési módok:
          </p>
          <p className='low-title-content2'>
            &emsp;•	bankszámlára átutalással (közlemény szükséges)<br />
            &emsp;•	kézpénz fizetési lehetőség az irodában<br />
            &emsp;•	részletfizetési lehetőség<br />
            &emsp;• e-titán rendszeren belüli simple pay fizetés
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles-aszf'>
            Elméleti képzés díja:
          </p>
          <p className='low-title-content2'>
            Tantermi képzés:<br/>
            28 óra, napi 4 óra, azaz 7 alkalom (hétköznapokon 17:00 órától).
            19 óra kresz. 6 óra vezetéselmélet és 3 óra szerkezettan bontásban.
            Hiányozni nem lehet, ha mégis akkor pótórán kell részt venni.<br/>
            E-learning KRESZ + elsősegély<br/>
            180 nap - 75 óra&emsp;&emsp;49.000 Ft - B<br/>
            E-learning KRESZ<br/>
            180 nap - 75 óra&emsp;&emsp;49.000 Ft - A, A1, A2<br/>
            180 nap - 75 óra&emsp;&emsp;45.000 Ft - AM<br/>
            A tanuló a rendszerbe való belépéstől számítva 180 napon belül 75 órát
            használhat fel. Amennyiben nem sikerült befejeznie a tanfolyamot
            vásárolhat még plusz 10 órát amelyet 30 napon belül kell teljesítenie (ennek ára: 8.000 Ft).
            A tanfolyamot a regisztrációtól számítva 90 napon belül el kell
            kezdeni. Amennyiben nem kezdi el, a tandíjat nem áll módunkban visszafizetni.
            Az elméleti tanfolyam befejezése után a tanuló tanúsítványt kap.
            Az első elméleti vizsgára való jelentkezést az autóiskola intézi.
            Sikertelen vizsga esetén a tanuló a KAV kijelölt irodájában a vizsgadíj befizetése után kérheti következő vizsga időpontját.
            Az elméleti képzést, valamint az első vizsgadíjat egy összegben kell befizetni a beiratkozáskor.
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles-aszf'>
            Gyakorlati képzés:
          </p>
          <p className='low-title-content2'>
            1-30 óráig 	9500 Ft/óra<br />
            31 órától	9500 Ft/óra<br />
            A gyakorlati képzés megkezdésének feltétele a sikeres elméleti vizsga.
            Az előirt minimum óraszámok 29+1 óra, amelyek az alábbiakból állnak:<br />
            &emsp;a.)	9 óra alapoktatás <br />

            &emsp;b.)	20 óra főoktatás:<br />
            &emsp;&emsp;•	14 óra városi <br />
            &emsp;&emsp;•	4 óra országúti és<br />
            &emsp;&emsp;•	2 óra éjszakai vezetés<br />
            &emsp;&emsp;•	+ 1 óra vizsga<br />
            A gyakorlati tanóra 50 perces. Egy alkalom 2 órából áll, és egyben lehet levezetni.
            A gyakorlati képzés díját 10 óránként kell befizetni az autósiskola irodájában, vagy
            átutalással,  vagy az oktatónál is fizethető.
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles'>
            Vizsgadíjak
          </p>
          <p className='low-title-content2'>
            A közúti járművezetők vizsgáztatásával összefüggő díjakat a 84/2009. (XII. 30.) KHEM rendelet
            mellékletében található, amelyek az alábbi árakat tartalmazza:<br />
            Elméleti vizsga:		4.600 Ft<br />
            Forgalmi vizsga:	11.000 Ft<br />
            A forgalmi vizsga díját a tanuló személyesen befizeti az iskola pénztárába, amelyről
            igazolást kap. Megbízza ügyintézőnket a vizsgára való jelentkezés lebonyolításával.
          </p>
          <p className='low-titles'>
            577/2022. (XII. 23.) Korm. rendelet
          </p>
          <p className='low-title-content2'>
            9. § (2) A 24/2005. (IV. 21.) GKM rendelet 3. §-át a következő eltérésekkel kell alkalmazni:<br />
            &emsp;a) a tanulóval a képzésre irányuló szerződéses jogviszony létrehozására, a
            szerződés megkötésére, továbbá az ehhez kapcsolódó, a közúti járművezetők és a
            közúti közlekedési szakemberek képzésének és vizsgáztatásának általános
            szabályairól szóló 179/2011. (IX. 2.) Korm. rendelet 2. § (1) bekezdés 8.
            pontja szerinti képzési költség (a továbbiakban: képzési költség), valamint
            az annak alapját képező díjtételek beszedésére és számla kiállítására kizárólag
            a képző szerv jogosult, amely köteles erről a tanuló részére tájékoztatást adni;<br />
            &emsp;b) a képző szerv a tanulót az általa végzett képzés és a vizsgára bocsátás
            feltételeiről írásban tájékoztatja, amely kiterjed a képzési költségre, valamint
            arra, hogy a képzésben közreműködő szakoktató a tanulótól díj vagy ellenszolgáltatás
            átvételére nem jogosult, a képző szervnek számla ellenében megfizetett képzési költség
            minden képzéssel kapcsolatos díjat tartalmaz;<br />
            &emsp;c) a képző szerv a képzési tevékenységébe szakoktatót a felnőttképzésről szóló 2013. évi
            LXXVII. törvény 11. § (3) bekezdésében foglaltak szerint vonhat be; a szakoktató képzési
            közreműködéséért kizárólag a képzést végző képző szervtől jogosult díjazásra.<br />
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles-aszf'>
            A tanuló jogai és kötelezettségei:
          </p>
          <p className='low-title-content2'>
            &emsp;a.)	A tanulónak joga van a szerződésben meghatározott tanfolyamon
            részt vennie, valamint elméleti és gyakorlati vizsgát tennie.<br />
            &emsp;b.)	A tanulónak a személyazonosságát érvényes személyi igazolvánnyal kell igazolnia.
            Legkésőbb a vizsga napján igazolnia kell a vizsgaközpont részére az
            alapfokú iskolai végzettségét. Amennyiben nem igazolta annak teljesítéséig
            a következő vizsgára nem bocsátható, valamint részére vizsgaigazolás nem állítható ki.<br />
            Az iskolai végzettség megléte igazolható:<br />
            &emsp;-	személyazonosító okmányokba Magyarországon bejegyzett saját jogon szerzett doktori címmel<br />
            &emsp;-	iskolai végzettség igazolására szóló okmány eredeti, vagy a közjegyző, vagy
            a kiállító által hitelesített példányával. Külföldi bizonyítványok és oklevelek
            esetén az eredeti okmányokkal és annak hiteles fordításával (A hiteles fordítás
            nem kötelező, ha az oklevél, vagy bizonyítvány adott tartalmában és formájában
            szerepel a közlekedési hatóság által létrehozott Bizonyítvány és Oklevél nyilvántartásában)<br />
            &emsp;-	külföldi bizonyítványok és oklevelek esetén olyan – hatáskörrel rendelkező –
            magyar hatóság által hozott elismerő határozattal , honosítási záradékkal, hatósági
            bizonyítvánnyal, hatósági igazolással, tájékoztatással vagy három hónapnál nem régebbi
            Magyarországon felsőoktatási intézmény által kiállított hallgatói jogviszony
            igazolással, amely kimondja, hogy az adott bizonyítvány vagy oklevél legalább alapfokú
            végzettséget igazol, vagy annak meglétét előfeltételezi.<br />

            &emsp;c.)	Amennyiben a tanuló a vizsgán valamelyik vizsgatárgyból nem felelt meg
            legkorábban három munkanap eltelte után tehet újabb vizsgát.<br />
            &emsp;d.)	A vizsgán meg nem jelenés, késés, vagy sikertelen vizsga esetén újabb vizsga csak a vizsgadíj befizetése után lehetséges.<br />
            &emsp;e.)	A tanuló személyi adataiban történő változást 8 napon belül köteles bejelenteni a képzőszerv, illetve a KAV felé.<br />
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles-aszf'>
            A képzőszerv jogai és kötelezettségei:
          </p>
          <p className='low-title-content2'>
            &emsp;a.)	A képzőszerv jogosult megtagadni az oktatást, ha a tanuló ittasan, drogosan,
            fertőző betegen, vagy szennyezett ruhában jelenik meg.<br />
            &emsp;b.)	Jogosult a tanuló oktatását felfüggeszteni, ha a tandíjfizetés elmarad.<br />
            &emsp;c.)	Jogosult a tandíjat módosítani, ha a tanuló jelentkezésének időpontjától számítva
            az üzemanyagok árának változása eléri a 20%-ot.<br />
            &emsp;d.)	Köteles a tanulót a vizsgára felkészíteni<br />
            &emsp;e.)	Köteles a tanuló kérésére pontos elszámolást adni a befizetett tandíj mértékéről és a leoktatott órákról<br />
            &emsp;f.)	A gyakorlati oktatás során az oktatóval előre leegyeztetett időpontot 2 munkanappal
            előtte van lehetősége lemondani. Ellenkező esetben a tanóráról való hiányzást az oktató a tanulót kettő tanórával terheli meg.<br />
            &emsp;g.)	Köteles a tanulónak az áthelyezéshez szükséges nyomtatványokat a kéréstől számítva három munkanapon belül kiadni.<br />
          </p>
          </Fade>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
export default Aszf;