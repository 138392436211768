import React from 'react'
import { useEffect } from 'react';
import BlinkingButton from '../Components/BlinkingButton/BlinkingButton';
import BlinkingButtonEng from '../Components/BlinkingButton/BlinkingButtonEng';
import { AnimatePresence, motion } from 'framer-motion';

function ElmeletiKepzes() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - Elméleti képzés'
  }, [])



  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="homePage"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className='title'>
          Elméleti képzés
        </div>
        <div className='low-title'>
          <div className='bcategory'>
            Elméletet (Kreszt) nálunk e-learning rendszeren, otthon saját tempóban számítógépen végezhetik el a tanulók.
          </div>
          <div className='low-titles-elmeleti'>
            Jelentkezz online magyarul:
          </div>
          <p className="apply-button">
            <BlinkingButton />
          </p>
          <div className='low-titles-elmeleti'>
            Apply online in english:
          </div>
          <p className="apply-button">
            <BlinkingButtonEng />
          </p>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
export default ElmeletiKepzes;