import React from 'react'
import { useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MapSection from '../Components/GoogleMap/Map';
import OddEvenCalendar from '../Components/Calendar/OddEvenCalendar';
import BlinkingButton from '../Components/BlinkingButton/BlinkingButton';
import BlinkingButtonEng from '../Components/BlinkingButton/BlinkingButtonEng';
import { AnimatePresence, motion } from 'framer-motion';
import Fade from 'react-reveal/Fade';


const location = {
  address: 'Siófok, Zákonyi Ferenc köz, 8600',
  lat: 46.90639220773316,
  lng: 18.050912586507664,
}
46.90639220773316, 18.050912586507664


const Contact = () => {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - Elérhetőségek'
  }, [])

  emailjs.init("slCDG9KliU1w0z5lI");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_i249h1i', 'template_ng3qo5c', e.target)
      .then((result) => {
        console.log(result.text);
        toast.success('Email sikeresen elküldve!');
      }, (error) => {
        console.log(error.text);
        toast.error('Email sikertelenül elküldve!');
      });
  };


  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="homePage"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className='title'>
          <img className='logo' src='./images/Feher_keret_nelkul_logo.png' alt='' width='600' height='200' />
        </div>
        <div className='low-title'>
          <Fade bottom>
          <p className='low-titles'>
            Bankszámlaszámunk:
          </p>
          <p className='low-title-content2'>
            &emsp;BALATON AUTÓSISKOLA KFT.<br />
            &emsp;<b>12023101 01881804 00100009</b><br />
            &emsp;RAIFFEISEN BANK<br />
            &emsp;Közleménybe Név + mit utal (pl.: óra mennyisége)
          </p>
          </Fade>
          <Fade bottom>
          <p className='contact'>
            Elérhetőségek
          </p>
          <p className='low-titles'>
            Cím:<br />
          </p>
          <p className='low-title-content2'>
            &emsp;Székhely: 8600 Siófok, Kristály utca 39.<br />
            &emsp;Ügyfélfogadó iroda: 8600 Siófok, Kálmán Imre sétány 8.<br />
            &emsp;Megközelítés: Víztorony felől, a Zákonyi Ferenc közben.
          </p>
          </Fade>
          <Fade bottom>
          <OddEvenCalendar />
          </Fade>
          <Fade bottom>
          <p className='low-titles'>
            Nyitvatartás:
          </p>
          <p>
            &emsp;Páros héten: hétfőtől péntekig --{'>'} 9:00-13:00<br />
            &emsp;Páratlan héten: hétfőtől péntekig --{'>'} 12:30-16:30<br />
            &emsp;Minden szombaton --{'>'} 8:00-12:00
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-titles'>
            Iroda telefonszáma:<br />
          </p>
          <p>
            &emsp;+36 204038020
          </p>
          <p className='low-titles'>
            Mobiltelefonszámok:<br />
          </p>
          <p className='low-title-content2'>
            &emsp;+36 302169252<br />
            &emsp;+36 706621010<br />
            &emsp;+36 302172486<br />
            &emsp;+36 309311519<br />
            &emsp;+36 209643642
          </p>
          </Fade>
          <Fade bottom>
          <p className='contact'>
            Kapcsolati űrlap
          </p>
          <p className='low-title-content2'>
            A *-al jelölt mező kitöltése kötelező.
          </p>
          <form ref={form} onSubmit={sendEmail} className='low-title-content2'>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Név*</label>
              <input type="text" className="form-control" id="exampleFormControlInput1" name='user_nev' required />
              <label htmlFor="exampleFormControlInput1" className="form-label">Email cím*</label>
              <input type="text" className="form-control" id="exampleFormControlInput2" placeholder="pelda@gmail.com" name='user_email' required />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlTextarea1" className="form-label">Tárgy*</label>
              <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" name='targy' required></textarea>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-dark">Küldés</button>
              <ToastContainer />
            </div>
          </form>
          </Fade>
          <Fade bottom>
          <img src='./images/iroda3.JPG' className='iroda-kep2' alt='' width={900} height={700} />
          </Fade>
          <Fade bottom>
          <MapSection location={location} zoomLevel={17} />
          </Fade>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
export default Contact;