import React from 'react'
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

function AllamiTamogatas() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - Állami támogatás'
  }, [])


  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="homePage"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className='title'>
          Ki jogosult az állami támogatásra?
        </div>
        <div className='low-title'>
          <p className='low-title-content2'>
            1. Magyar állampolgár, (vagy a nemzeti felsőoktatásról szóló 2011. évi CCIV. törvény 39. § (1) bekezdés a) és c)–h) pontjában meghatározott személy) vagy<br />
            2. A vizsga napján a 20. életévét nem töltötte még be.<br />
            3. A 20. életévét már betöltötte és a vizsga napján:<br />
            &emsp;•	csecsemőgondozási díjban,<br />
            &emsp;•	gyermekgondozási díjban vagy<br />
            &emsp;•	gyermekgondozást segítő ellátásban
            részesült, és nem vette korábban igénybe a támogatást.<br />
            4. Sikeres KRESZ-vizsga.
          </p>
          <p className='low-titles'>
            Támogatás mértéke
          </p>
          <p className='low-title-content2'>
            Az 55//2018 (III.23.) Korm. rendelet alapján 25.000 Ft állami támogatást kap vissza.
          </p>
          <p className='low-titles'>
            Mit kell tenni ahhoz, hogy megkapja az állami támogatást?
          </p>
          <p className='low-title-content3'>
            •	Az igazoltan elvégzett elméleti tanfolyam, valamint
            a sikeres KRESZ-vizsga után bejön az irodánkba, ahol kiállítunk egy igazolást a befizetett összegről.<br />
            •	Beadja az iskolától kapott igazolást a Magyar
            Államkincstár Nyugdíjfolyósító Igazgatósághoz
            (személyesen vagy elektronikusan), ez a sikeres KRESZ-vizsgától számított 1 éven belül lehetséges.<br />
            •	30 napon belül a megadott számlára kerül az összeg.
          </p>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
export default AllamiTamogatas;