import React, { useState, useEffect, useRef } from 'react'

import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <header className={`navbar-container ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="navbar-navbar-interactive">
        <a href='/'>
          <img
            alt={props.imageAlt}
            src={props.imageSrc}
            className="navbar-image"
          />
        </a>
        <div className="navbar-container1">
          <div data-thq="thq-navbar-nav" className="navbar-desktop-menu">
            <nav className="navbar-links">
              <svg viewBox="0 0 1024 1024" className="navbar-icon">
                <path
                  d="M426 854h-212v-342h-128l426-384 426 384h-128v342h-212v-256h-172v256z"
                  className=""
                ></path>
              </svg>
              <a href='/'>
              <span className="navbar-link1 thq-body-small">{props.link1}</span>
              </a>
              <svg viewBox="0 0 1024 1024" className="navbar-icon02">
                <path
                  d="M504 466q44 12 73 24t61 33 49 53 17 76q0 62-41 101t-109 51v92h-128v-92q-66-14-109-56t-47-108h94q8 90 126 90 62 0 89-23t27-53q0-72-128-104-200-46-200-176 0-58 42-99t106-55v-92h128v94q66 16 101 60t37 102h-94q-4-90-108-90-52 0-83 22t-31 58q0 58 128 92z"
                  className=""
                ></path>
              </svg>
              <a href='/arak'>
              <span className="navbar-link2 thq-body-small">{props.link2}</span>
              </a>
              <svg viewBox="0 0 1024 1024" className="navbar-icon04">
                <path
                  d="M384 554q64 0 140 18t139 60 63 94v128h-684v-128q0-52 63-94t139-60 140-18zM640 512q-26 0-56-10 56-66 56-160 0-38-16-86t-40-76q30-10 56-10 70 0 120 51t50 121-50 120-120 50zM214 342q0-70 50-121t120-51 120 51 50 121-50 120-120 50-120-50-50-120zM712 560q106 16 188 59t82 107v128h-172v-128q0-98-98-166z"
                  className=""
                ></path>
              </svg>
              <a href='/oktatok'>
              <span className="navbar-link3 thq-body-small">{props.link3}</span>
              </a>
              <svg
                viewBox="0 0 952.5394285714285 1024"
                className="navbar-icon06"
              >
                <path
                  d="M936.571 273.143c14.286 20.571 18.286 47.429 10.286 73.714l-157.143 517.714c-14.286 48.571-64.571 86.286-113.714 86.286h-527.429c-58.286 0-120.571-46.286-141.714-105.714-9.143-25.714-9.143-50.857-1.143-72.571 1.143-11.429 3.429-22.857 4-36.571 0.571-9.143-4.571-16.571-3.429-23.429 2.286-13.714 14.286-23.429 23.429-38.857 17.143-28.571 36.571-74.857 42.857-104.571 2.857-10.857-2.857-23.429 0-33.143 2.857-10.857 13.714-18.857 19.429-29.143 15.429-26.286 35.429-77.143 38.286-104 1.143-12-4.571-25.143-1.143-34.286 4-13.143 16.571-18.857 25.143-30.286 13.714-18.857 36.571-73.143 40-103.429 1.143-9.714-4.571-19.429-2.857-29.714 2.286-10.857 16-22.286 25.143-35.429 24-35.429 28.571-113.714 101.143-93.143l-0.571 1.714c9.714-2.286 19.429-5.143 29.143-5.143h434.857c26.857 0 50.857 12 65.143 32 14.857 20.571 18.286 47.429 10.286 74.286l-156.571 517.714c-26.857 88-41.714 107.429-114.286 107.429h-496.571c-7.429 0-16.571 1.714-21.714 8.571-4.571 6.857-5.143 12-0.571 24.571 11.429 33.143 50.857 40 82.286 40h527.429c21.143 0 45.714-12 52-32.571l171.429-564c3.429-10.857 3.429-22.286 2.857-32.571 13.143 5.143 25.143 13.143 33.714 24.571zM328.571 274.286c-3.429 10.286 2.286 18.286 12.571 18.286h347.429c9.714 0 20.571-8 24-18.286l12-36.571c3.429-10.286-2.286-18.286-12.571-18.286h-347.429c-9.714 0-20.571 8-24 18.286zM281.143 420.571c-3.429 10.286 2.286 18.286 12.571 18.286h347.429c9.714 0 20.571-8 24-18.286l12-36.571c3.429-10.286-2.286-18.286-12.571-18.286h-347.429c-9.714 0-20.571 8-24 18.286z"
                  className=""
                ></path>
              </svg>
              <div
                data-thq="thq-dropdown"
                className="navbar-thq-dropdown list-item"
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="navbar-dropdown-toggle"
                >
                  <span className="navbar-text">
                    <span className="">Képzések</span>
                    <br className=""></br>
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="navbar-dropdown-arrow"
                  ></div>
                  <div className="navbar-container2">
                    <svg viewBox="0 0 1024 1024" className="navbar-icon08">
                      <path d="M298 426h428l-214 214z" className=""></path>
                    </svg>
                  </div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className="navbar-dropdown-list"
                >
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown list-item"
                  >
                    <a href='/elmeletikepzes'>
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle01"
                    >
                      <span className="navbar-text03">
                        <span className="">Elméleti képzés</span>
                        <br className=""></br>
                      </span>
                    </div>
                    </a>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown01 list-item"
                  >
                    <a href='/allamitamogatas'>
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle02"
                    >
                      <span className="navbar-text06">Állami támogatás</span>
                    </div>
                    </a>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown02 list-item"
                  >
                    <a href='/bkategoria'>
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle03"
                    >
                      <span className="navbar-text07">B kategória</span>
                    </div>
                    </a>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown03 list-item"
                  >
                    <a href='/akategoriak'>
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle04"
                    >
                      <span className="navbar-text08">
                        <span className="">AM, A1, A2, A kategória</span>
                        <br className=""></br>
                      </span>
                    </div>
                    </a>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown04 list-item"
                  >
                    <a href='/aszf'>
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle05"
                    >
                      <span className="navbar-text11">
                        <span className="">ÁSZF</span>
                        <br className=""></br>
                      </span>
                    </div>
                    </a>
                  </li>
                </ul>
              </div>
              <svg viewBox="0 0 1024 1024" className="navbar-icon10">
                <path
                  d="M192 0v1024h768v-1024h-768zM576 256.33c70.51 0 127.67 57.16 127.67 127.67s-57.16 127.67-127.67 127.67-127.67-57.16-127.67-127.67 57.16-127.67 127.67-127.67v0zM768 768h-384v-64c0-70.696 57.306-128 128-128v0h128c70.696 0 128 57.304 128 128v64z"
                  className=""
                ></path>
                <path d="M64 64h96v192h-96v-192z" className=""></path>
                <path d="M64 320h96v192h-96v-192z" className=""></path>
                <path d="M64 576h96v192h-96v-192z" className=""></path>
                <path d="M64 832h96v192h-96v-192z" className=""></path>
              </svg>
              <a href='/kapcsolat'>
              <span className="navbar-link4 thq-body-small">{props.link5}</span>
              </a>
              <svg
                viewBox="0 0 731.4285714285713 1024"
                className="navbar-icon16"
              >
                <path
                  d="M621.714 548.571v-365.714h-256v649.714c29.143-15.429 76-42.286 121.714-78.286 61.143-48 134.286-122.857 134.286-205.714zM731.429 109.714v438.857c0 240.571-336.571 392.571-350.857 398.857-4.571 2.286-9.714 3.429-14.857 3.429s-10.286-1.143-14.857-3.429c-14.286-6.286-350.857-158.286-350.857-398.857v-438.857c0-20 16.571-36.571 36.571-36.571h658.286c20 0 36.571 16.571 36.571 36.571z"
                  className=""
                ></path>
              </svg>
              <a href='/adatvedelem'>
              <span className="navbar-link5 thq-body-small">{props.link6}</span>
              </a>
              <svg viewBox="0 0 1024 1024" className="navbar-icon18">
                <path
                  d="M0 832h1024v128h-1024zM128 576h128v192h-128zM320 320h128v448h-128zM512 512h128v256h-128zM704 128h128v640h-128z"
                  className=""
                ></path>
              </svg>
              <a href='/akovsm'>
              <span className="navbar-link6 thq-body-small">{props.link7}</span>
              </a>
              {/* <a href='/live'>
              <span className="navbar-link6 thq-body-small">{props.link8}</span>
              </a> */}
            </nav>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="navbar-burger-menu" onClick={toggleMobileMenu}>
          <svg viewBox="0 0 1024 1024" className="navbar-icon20">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              className=""
            ></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className={`navbar-mobile-menu ${isMobileMenuOpen ? 'active' : ''}`}>
          <div className="navbar-nav">
            <div className="navbar-top">
              <a href='/'>
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                className="navbar-image1"
              />
              </a>
              <div data-thq="thq-close-menu" className="navbar-close-menu" onClick={toggleMobileMenu}>
                <svg viewBox="0 0 1024 1024" className="navbar-icon22">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                    className=""
                  ></path>
                </svg>
              </div>
            </div>
            <nav className="navbar-links1">
              <a href='/'>
              <span className="thq-body-small navbar-link11">{props.link1}</span>
              </a>
              <a href='/arak'>
              <span className="navbar-link21 thq-body-small">
                {props.link2}
              </span>
              </a>
              <a href='/oktatok'>
              <span className="navbar-link31 thq-body-small">
                {props.link3}
              </span>
              </a>
              <div
                data-thq="thq-dropdown"
                className="navbar-thq-dropdown1 list-item"
                ref={dropdownRef}
                onClick={handleDropdownToggle}
              >
                <div
                  data-thq="thq-dropdown-toggle"
                  className="navbar-dropdown-toggle06"
                >
                  <span className="navbar-text14 thq-body-small">
                    <span className="">Képzések</span>
                    <br className=""></br>
                  </span>
                  <div
                    data-thq="thq-dropdown-arrow"
                    className="navbar-dropdown-arrow1"
                  ></div>
                  <div className="navbar-container3">
                    <svg viewBox="0 0 1024 1024" className="navbar-icon24">
                      <path d="M298 426h428l-214 214z" className=""></path>
                    </svg>
                  </div>
                </div>
                <ul
                  data-thq="thq-dropdown-list"
                  className={`navbar-dropdown-list1 ${isDropdownOpen ? 'active' : ''}`}
                >
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown05 list-item"
                  >
                    <a href='/elmeletikepzes'>
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle07"
                    >
                      <span className="navbar-text17">
                        <span className="">Elméleti képzés</span>
                        <br className=""></br>
                      </span>
                    </div>
                    </a>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown06 list-item"
                  >
                    <a href='/allamitamogatas'>
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle08"
                    >
                      <span className="navbar-text20">Állami támogatás</span>
                    </div>
                    </a>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown07 list-item"
                  >
                    <a href='/bkategoria'>
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle09"
                    >
                      <span className="navbar-text21">B kategória</span>
                    </div>
                    </a>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown08 list-item"
                  >
                    <a href='/akategoriak'>
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle10"
                    >
                      <span className="navbar-text22">
                        <span className="">AM, A1, A2, A kategória</span>
                        <br className=""></br>
                      </span>
                    </div>
                    </a>
                  </li>
                  <li
                    data-thq="thq-dropdown"
                    className="navbar-dropdown09 list-item"
                  >
                    <a href='/aszf'>
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="navbar-dropdown-toggle11"
                    >
                      <span className="navbar-text25">
                        <span className="">ÁSZF</span>
                        <br className=""></br>
                      </span>
                    </div>
                    </a>
                  </li>
                </ul>
              </div>
              <a href='/kapcsolat'>
              <span className="thq-body-small navbar-link51">
                {props.link5}
              </span>
              </a>
              <a href='/adatvedelem'>
              <span className="thq-body-small navbar-link61">
                {props.link6}
              </span>
              </a>
              <a href='/akovsm'>
              <span className="thq-body-small navbar-link71">
                {props.link7}
              </span>
              </a>
              {/* <a href='/live'>
              <span className="thq-body-small navbar-link71">
                {props.link8}
              </span>
              </a> */}
            </nav>
          </div>
          <div className="navbar-icon-group">
          <a href="https://instagram.com/balaton_autosiskola?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="thq-icon-small"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                className=""
              ></path>
            </svg>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100091974471166" target="_blank" rel="noopener noreferrer">
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="thq-icon-small"
            >
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
                className=""
              ></path>
            </svg>
            </a>
          </div>
        </div>
      </header>
    </header>
  )
}

Navbar.defaultProps = {
  imageAlt: 'image',
  link3: 'Oktatóink',
  rootClassName: '',
  link1: "Kezdőlap",
  link2: 'Díjak',
  link7: 'ÁKÓ - VSM',
  link8: 'Balaton live',
  link6: 'Adatvédelem',
  link4: 'Képzések',
  imageSrc: './images/Kek_logo.png',
  imageSrc1: './images/Kek_logo.png',
  imageAlt1: 'image',
  link5: 'Elérhetőség',
}

Navbar.propTypes = {
  imageAlt: PropTypes.string,
  link3: PropTypes.string,
  rootClassName: PropTypes.string,
  link1: PropTypes.string,
  link2: PropTypes.string,
  link7: PropTypes.string,
  link8: PropTypes.string,
  link6: PropTypes.string,
  link4: PropTypes.string,
  imageSrc: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt1: PropTypes.string,
  link5: PropTypes.string,
}

export default Navbar
