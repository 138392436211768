import React from 'react';
import { useAuth } from './AuthProvider';

const Checkout = () => {
  const { user } = useAuth();

  const handleCheckout = async () => {
    try {
      const response = await fetch('https://us-central1-balaton-autosisklola-live.cloudfunctions.net/createSimplePaySession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: user.uid }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.paymentUrl) {
        window.location.href = data.paymentUrl;
      } else {
        throw new Error('Failed to obtain payment URL');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      alert('There was an error during checkout. Please try again.');
    }
  };

  return <button onClick={handleCheckout}>subscribe</button>;
};

export default Checkout;
