import React from 'react'

import PropTypes from 'prop-types'

import './turelmesek.css'

const Turelmesek = (props) => {
  return (
    <div className="thq-section-padding">
      <animate-on-reveal
        animation="bounceIn"
        duration="500ms"
        delay="0s"
        direction="normal"
        easing="ease"
        iteration="1"
      >
        <div
          data-thq-animate-on-reveal="true"
          className="turelmesek-max-width thq-section-max-width"
        >
          <div className="thq-flex-row turelmesek-container">
            <div className="turelmesek-column">
              <div className="turelmesek-content">
                <h1 className="turelmesek-heading1 thq-heading-1">
                  {props.heading1}
                </h1>
                <h1 className="turelmesek-heading2 thq-heading-1">
                  Akkor megtaláltad a neked valót!
                </h1>
              </div>
              <div className="turelmesek-actions"></div>
            </div>
            <div className="turelmesek-container1">
              <img
                alt={props.image1Alt}
                src={props.image1Src}
                className="turelmesek-image1 thq-img-ratio-4-3"
              />
            </div>
          </div>
        </div>
      </animate-on-reveal>
    </div>
  )
}

Turelmesek.defaultProps = {
  action2: 'Secondary action',
  action1: 'Main action',
  image1Alt: 'PlaceholderImage1314',
  heading1:
    'Olyan autósiskolát keresel, ahol fiatalos, lendületes és TÜRELMESEK az oktatók? Korrektül a vezetési időt aktív oktatással/vezetés tanítással töltik?',
  image1Src: './images/balaton_bejarat.jpg',
}

Turelmesek.propTypes = {
  action2: PropTypes.string,
  action1: PropTypes.string,
  image1Alt: PropTypes.string,
  heading1: PropTypes.string,
  image1Src: PropTypes.string,
}

export default Turelmesek
