import React from 'react'

import PropTypes from 'prop-types'

import './motto.css'

const Motto = (props) => {
  return (
    <div className="thq-section-padding">
      <animate-on-reveal
        animation="bounceIn"
        duration="500ms"
        delay="0s"
        direction="normal"
        easing="ease"
        iteration="1"
      >
        <div
          data-thq-animate-on-reveal="true"
          className="motto-max-width thq-section-max-width thq-flex-row"
        >
          <img
            alt={props.image1Alt}
            src={props.image1Src}
            className="motto-hero-image thq-img-ratio-4-3"
          />
          <div className="motto-column">
            <div className="motto-content">
              <h1 className="motto-text thq-heading-1">{props.heading1}</h1>
              <div className="motto-actions">
                <a href="https://etitan.hu/site/hu/WebReg/IskolaiRegisztracio?azonosito=hzXvSuWYrUC1_6_zjIBazg" target="_blank" rel="noopener noreferrer">
                <button className="thq-button-filled motto-button">
                  <span className="thq-body-small">{props.action1}</span>
                </button>
                </a>
                <a href="https://etitan.hu/site/WebReg/Registration/hzXvSuWYrUC1_6_zjIBazg" target="_blank" rel="noopener noreferrer">
                <button className="thq-button-filled motto-button1">
                  <span className="thq-body-small">{props.action2}</span>
                </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </animate-on-reveal>
    </div>
  )
}

Motto.defaultProps = {
  action2: 'Click here to apply for english course!',
  image1Alt:
    'Mottónk: Nálunk nem csak jogosítványt szerzel, mi megtanítunk biztonságosan közlekedni is!',
  action1: 'Kattints ide az online jelentkezéshez!',
  image1Src: './images/Kek_feher_logo.png',
  heading1:
    'Mottónk: Nálunk nem csak jogosítványt szerzel, mi megtanítunk biztonságosan közlekedni is!',
}

Motto.propTypes = {
  action2: PropTypes.string,
  image1Alt: PropTypes.string,
  action1: PropTypes.string,
  image1Src: PropTypes.string,
  heading1: PropTypes.string,
}

export default Motto
