import React from 'react'

import './Footer.css'
import SocialIcons from '../SocialIcons/SocialIcons'

const Footer2 = (props) => {
  return (
    <div className="home-container">
      <footer className="home-footer">
        <div className="home-container1-footer">
          <img
            src="./images/Feher_keret_nelkul_logo.png"
            alt="image"
            className="home-image"
          />
          <nav className="home-nav">
            <span className="home-text1"><a href="/" className="nav-link">Főoldal</a></span>
            <span className="home-text1"><a href="/oktatok" className="nav-link">Oktatóink</a></span>
            <span className="home-text2"><a href="/arak" className="nav-link">Árak</a></span>
            <span className="home-text3"><a href="/kapcsolat" className="nav-link">Kapcsolat</a></span>
            <span className="home-text4"><a href="/aszf" className="nav-link">Ászf</a></span>
          </nav>
        </div>
        <div className="home-separator"></div>
        <div className="home-container2">
          <span className="home-text5">
            Copyright © 2023-2024 - autosiskolabalaton.hu
          </span>
          <div className="home-icon-group">
            <SocialIcons/>
          </div>
        </div>
        <div className="home-container3">
          <span className="home-text6">
            <b>Elérhetőség:</b> info@autosiskolabalaton.hu
          </span>
          <div className="home-icon-group1">
            <span className="material-icons">phone</span>
            <span className="home-text7">06 20 403 8020</span>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer2;
