import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from './Firebase';
import { 
    onAuthStateChanged, 
    signInWithPopup, 
    GoogleAuthProvider, 
    FacebookAuthProvider, 
    signInWithEmailAndPassword, 
    createUserWithEmailAndPassword,
    deleteUser,
    signOut
  } from 'firebase/auth';
import { doc, getDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      console.log("Logged out successfully!");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    const handleTabClose = event => {
      logout();
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      setLoading(false);
      if (user) {
        const userDoc = await getDoc(doc(db, 'subscriptions', user.uid));
        console.log("User Subscription Document:", userDoc.data());
        setHasAccess(userDoc.exists() && userDoc.data().isSubscribed);
      }
    });

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
      unsubscribe();
    };
  }, []);

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
  };

  const loginWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user') {
        console.log('User closed the Facebook login popup.');
      } else {
        console.error("Login failed: ", error);
      }
    }
  };

  const loginWithEmail = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const registerWithEmail = async (email, password) => {
    await createUserWithEmailAndPassword(auth, email, password);
  };

  const deleteUserAccount = async () => {
    if (user) {
      const userDocRef = doc(db, 'subscriptions', user.uid);
      await deleteDoc(userDocRef);

      const otherCollectionRefs = query(collection(db, 'otherCollection'), where('uid', '==', user.uid));
      const querySnapshot = await getDocs(otherCollectionRefs);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      await deleteUser(user);
      setUser(null);
    }
  };

  const value = {
    user,
    loading,
    hasAccess,
    loginWithGoogle,
    loginWithFacebook,
    loginWithEmail,
    registerWithEmail,
    deleteUserAccount,
    logout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
